<template>
    <div class="teaching-research-classroom-observation-records-view">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
               <expand-filter
                    :formData="formData"
                    :closeWidth="1360"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @treeClick="treeClick"
                    @treeClear="treeClear"
                    @changeSel="changeSel"
                >
                <div class="inline" slot="other-btn" v-has-permi="['classroomObservationRecords:save']"></div>
                <div slot="other-btn" style="flex: 1;display: flex;">
                    <Option
                        @addObservationRecords="addObservationRecords"
                        @exportTableInfo="exportTableInfo"
                        :exportLoading="exportLoading"
                    />
                </div>
                </expand-filter>
               <div class="table-wrapper">
                    <table-data
                        ref="table"
                        id="table"
                        v-loading="loadingTable"
                        :config="table_config"
                        :tableData="table_data"
                        :backgroundHeader="backgroundHeader"
                        :headerColor="headerColor"
                        @handleSelectionChange="handleSelectionChange"
                    >
                        <template v-slot:operation="slotData">
                            <el-button v-if="slotData.data.state == '2'" type="text" @click="edit(slotData.data)" v-hasPermi="['classroomObservationRecords:edit']" class="table-success-button">编辑</el-button>
                            <el-button v-if="slotData.data.state == '1'" type="text" @click="continueTour(slotData.data)" v-hasPermi="['classroomObservationRecords:tour']" class="table-success-button">继续巡课</el-button>
                            <el-button  v-if="slotData.data.state == '2'" type="text" @click="detail(slotData.data)" v-hasPermi="['classroomObservationRecords:info']" class="table-primary-button">详情</el-button>
                            <el-button type="text" @click="del(slotData.data)" v-hasPermi="['classroomObservationRecords:del']" class="table-danger-button">删除</el-button>
                        </template>
                    </table-data>
               </div>
               <Pagination v-if="show" :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow" @pagination="getList" />
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>
<script>
    import {
        // 业务组件
        Pagination,
        // 功能组件
        Loading,
        Error,
    } from 'common-local';
    import TableData from "@/components/scrollWrapper/Sub/TableData"
    import {
        classScheduleModel
    } from '@/models/ClassSchedule.js';
    import { downloadFile } from '@/libs/utils.js'
    import { TeachingResearchCourseScheduleTempModel } from '@/models/TeachingResearchCourseScheduleTemp.js';
    import FilterWrapper from './FilterWrapper.vue';
    import Option from './Option.vue';
    import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
    import onResize from "@/mixins/onResize";
    import RecordsList from '@/libs/pageListOptimal.js';
    export default {
        name: "TeachingResearchClassroomObservationRecordsView",
        mixins: [onResize],
        components: {
            Loading,
            Error,
            Pagination,
            TableData,
            FilterWrapper,
            Option,
            ExpandFilter
        },
        props: {

        },
        data () {
            return {
                loadingShow: false,
                errorShow: false,
                formData: {
                    data: [
                        {
                            type: 'input',
                            label: '',
                            placeholder: '观察标题',
                            key: 'observeTitle',
                            value: '',
                        },
                        {
                            type: 'select',
                            label: '',
                            placeholder: '部门名称',
                            key: 'departmentName',
                            value: '',
                            width: '',
                            list: [],
                        },
                        {
                            type: 'treeSelect',
                            label: '',
                            placeholder: '督导教师',
                            key: 'supervisingTeacher',
                            value: '',
                            width: '',
                            list: [],
                            defaultProps: {
                                children: 'children',
                                label: 'name'
                            },
                        },
                        {
                            type: 'select',
                            label: '',
                            placeholder: '年级',
                            key: 'grade',
                            value: '',
                            width: '',
                            list: [],
                        },
                        {
                            type: 'select',
                            label: '',
                            placeholder: '班级',
                            key: 'class',
                            value: '',
                            width: '',
                            list: [],
                        },
                        {
                            type: 'select',
                            label: '',
                            placeholder: '课程',
                            key: 'course',
                            value: '',
                            width: '',
                            list: [],
                        },
                        {
                            type: 'select',
                            label: '',
                            placeholder: '状态',
                            key: 'status',
                            value: '',
                            width: '',
                            list: [
                                {
                                    label:"待提交",
                                    value:'1',
                                },
                                {
                                    label:"已提交",
                                    value:'2',
                                }],
                        },
                        {
                            type: 'input',
                            label: '',
                            placeholder: '提交人',
                            key: 'consumNumber',
                            value: '',
                        },
                        {
                            type: 'datePick',
                            label: '',
                            startPlaceholder: '记录开始时间',
                            endPlaceholder: '记录结束时间',
                            key: 'day',
                            value: [],
                            picker: true,
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd HH:mm:ss'
                        },
                    ],
                    btnList: [
                        {
                            type: 'primary',
                            text: '查询',
                            fn: 'primary',
                        },
                        {
                            type: 'enquiry',
                            text: '重置',
                            fn: 'reset'
                        },
                    ],
                    btnFormType: true
                },
                /**
                 * 筛选数据
                 * */
                queryList: {
                    observeTitle: '',
                    departmentName: '',
                    supervisingTeacher: '',
                    grade: '',
                    class: '',
                    course: '',
                    status: '',
                    submitted: '',
                    day: ''
                },
                total: 0,
                listQuery:{
                    pageNum:1,
                    pageRow:20,
                    schoolId:"",
                    productId:"cloud_campus",
                    watchTitle:'',
                    deptId:'',
                    supervisingTeacherId:'',
                    gradeId:'',
                    classId:'',
                    courseId:'',
                    state:"",
                    createByName:'',
                    observedStartTime:'',
                    observedEndTime:'',
                },
                /**
                 * 筛选下拉选项
                 * supervisingTeacherList 督导教师
                 * departmentList 部门下拉
                 * gradeList 年级下拉
                 * classList 班级下拉
                 * courseList 课程下拉
                 * statusList 状态下拉
                 *
                 *
                 * */
                supervisingTeacherList: [],
                departmentList:[],
                gradeList: [],
                classList: [],
                courseList: [],
                statusList: [
                    // {
                    //     label:"全部",
                    //     value:'',
                    // },
                    {
                        label:"待提交",
                        value:'1',
                    },
                    {
                        label:"已提交",
                        value:'2',
                    }
                ],
                /**
                 * 表格数据
                 * */
                loadingTable: false,
                table_config: {
                    thead: [{
                    label: "观察标题",
                    prop: "watchTitle",
                    align: "center",
                    labelWidth:'120'
                    },
                    {
                        label: "督导教师",
                        prop: "supervisingTeacherName",
                        type:'popoverText',
                        align: "center",
                    },
                    {
                        label: "部门名称",
                        prop: "deptName",
                        labelWidth:'120',
                        align: "center",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "课程",
                        prop: "courseName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "上课时间",
                        prop: "observedTime",
                        labelWidth:'160',
                        align: "center",
                    },
                    {
                        label: "观察时间",
                        prop: "newCreateTime",
                        labelWidth:'160',
                        align: "center",
                    },
                    {
                        label: "异常反馈",
                        prop: "abnormality",
                        type: 'function',
                        align: "center",
                        callBack(row) {
                        if (row.abnormality == 1) {
                            return '无异常'
                        } else if (row.abnormality == 2) {
                            return '未反馈'
                        } else if (row.abnormality == 3){
                            return '已反馈'
                        }
                    }
                    },
                    {
                        label: "提交人",
                        prop: "createByName",
                        labelWidth:'120',
                        align: "center",
                    },
                    {
                        label: "状态",
                        prop: "state",
                        type: 'function',
                        align: "center",
                        callBack(row) {
                            if (row.state == 1) {
                                return '待提交'
                            } else if (row.state == 2) {
                                return '已提交'
                            }
                        },
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: 'operation',
                        className: 'text-spacing-reduction',
                        minWidth:'170px'
                    }
                    ],
                    check: true,
                    rowkey: 'id',
                    height: '',
                    isExpandAll: true
                },
                table_data: [],
                backgroundHeader: '#FAFBFC',
                headerColor: '#595959',
                schoolId:'',
                show:false,
                exportLoading: false
            }
        },
        created(){
            let _this = this;
            this.$on('goToDetail', (par) => {
                _this.goToDetail(par)
            })
            this.listQuery.schoolId = this.schoolId = this.$store.state.schoolId;
            this.init()
        },
        methods: {
            async goToDetail (params) {
                console.log(params,'params')
                await this.initPageData()
                await this.detail (params)
            },
            async init(){
                this.loadingTable = true;
                await this.initPageData();
                this.setPageInfo();
                this.show = true;
                await this.getList()
            },
            treeClick(data) {
                console.log(data,'treeClick')
                switch (data.dt.key) {
                    case "supervisingTeacher":
                        data.dt.value = data.data.name
                        this.queryList.supervisingTeacher = data.data.id
                        break
                    default:
                        break
                }
                data.selRef.blur()
            },
            treeClear(data) {
                switch (data.dt.key) {
                    case "supervisingTeacher":
                        this.queryList.supervisingTeacher = ''
                        break
                    default:
                        break
                }
            },
            clickBtn(ev) {
                switch (ev.item.fn) {
                    case 'primary':
                        this.formData.data.forEach(e => {
                            if (e.key !== 'supervisingTeacher') {
                                this.queryList[e.key] = e.value
                            }
                        })
                        this.inquiry()
                        break;
                    case 'reset':
                        this.formData.data.forEach((item) => {
                            item.value = '';
                        })
                        this.reset()
                        break;
                    case 'export':
                        this.clickExport(ev.item)
                        break;
                    default:
                        break;
                }
            },
            /**
             * @Description: 日期
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: 陈金伟
             * @Date: 2023-07-19 19:00:37
             */
            datePickerBlur() {
                this.choiceDate = ''
            },
            savePageInfo(){
                //存储筛选项及滚动条高度
                let recordsList =  new RecordsList()
                recordsList.set(this.$route.name, {
                    formData: this.formData,
                    listQuery: this.listQuery,
                    tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
                })
            },
            setPageInfo(){
                //赋值--存储筛选项及滚动条高度
                let recordsList =  new RecordsList()
                if(recordsList.get(this.$route.name)){
                    this.formData = recordsList.get(this.$route.name).formData;
                    this.listQuery = recordsList.get(this.$route.name).listQuery;
                    this.listQuery.pageNum = Number(this.listQuery.pageNum)
                }
            },
            setTableScrollHeight(){
                let recordsList =  new RecordsList()
                if(recordsList.get(this.$route.name)){
                    setTimeout(()=>{
                        this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name) 
                    },500)
                }
            },
            /**
             * @Description: 课堂观察记录列表
             * @DoWhat:
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2022-11-17 16:51:26
             */
            getList(){
                this.loadingTable = true;
                const classscheduleModel = new classScheduleModel();
                classscheduleModel.getClassroomRecordList(this.listQuery).then((res) =>{
                if (res.data.code == '200') {
                    let list = res.data.data.list;
                    list.forEach((item) => {
                        if (item.createTime) {
                            this.$set(item, 'newCreateTime',item.createTime.substring(0, item.createTime.length - 3) )
                        } else {
                            this.$set(item, 'newCreateTime', '')
                        }
                    })
                    this.table_data = list;
                    this.total = res.data.data.totalCount;
                    this.setTableScrollHeight();
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false;
            })
            },
            /**
             * @Description: 头部筛选数据初始化
             * @DoWhat: 头部筛选
             * @UseScenarios:
             * @Attention:
             * @Author: xwn
             * @Date: 2022-11-18 09:51:19
             */
            async initPageData(){
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                //获取部门下拉列表
                await teachingResearchCourseScheduleTempModel.getDepartmentList({'schoolId': this.schoolId}).then((res) => {
                if (res.data.code == '200') {
                    this.departmentList = res.data.data;
                    this.formData.data.find(e => e.key === 'departmentName').list = res.data.data.map(item=>{
                        return Object.assign({}, item,{
                            label: item.name,
                            value: item.id
                        } )
                    })
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                })
                //获取督导教师下拉列表
                await teachingResearchCourseScheduleTempModel.getSchoolTeacherList({'schoolId': this.schoolId, "applyPerson": "1"}).then((res) => {
                if (res.data.code == '200') {
                    this.setDisabledOption(res.data.data);
                    this.supervisingTeacherList = res.data.data;
                    this.formData.data.find(e => e.key === 'supervisingTeacher').list = res.data.data
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                })
                //获取年级下拉列表
                await teachingResearchCourseScheduleTempModel.getGradeList({'schoolId': this.schoolId}).then((res) => {
                if (res.data.code == '200') {
                    this.gradeList = res.data.data;
                    this.formData.data.find(e => e.key === 'grade').list = res.data.data.map(item=>{
                        return Object.assign({}, item,{
                            label: item.name,
                            value: item.id
                        } )
                    })
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                })
                
                //获取课程下拉列表
                await teachingResearchCourseScheduleTempModel.getSchoolSubjectsList({'schoolId': this.schoolId, 'dictKey':"teachingSubjects"}).then((res) => {
                if (res.data.code == '200') {
                    this.courseList = res.data.data
                    this.formData.data.find(e => e.key === 'course').list = res.data.data.map(item=>{
                        return Object.assign({}, item,{
                            label: item.name,
                            value: item.id
                        } )
                    })
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
            })
            },
            /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
         setDisabledOption (list) {
            list.forEach((item) => {
                if (item.key !== 'teacher') {
                    this.$set(item, 'disabled', true)
                } else {
                    this.$set(item, 'disabled', false)
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption (item.children)
                }
            })
         },

            /**
             * @Description: 查询
             * @DoWhat: 查询
             * @UseScenarios: 查询
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:23:57
             */
            inquiry () {
                if (!this.queryList.day) {
                    this.queryList.day = '';
                }
                this.listQuery.pageNum = 1
                this.listQuery.pageRow = 20
                this.listQuery.watchTitle = this.queryList.observeTitle
                this.listQuery.deptId = this.queryList.departmentName
                this.listQuery.supervisingTeacherId = this.queryList.supervisingTeacher
                this.listQuery.gradeId = this.queryList.grade
                this.listQuery.classId = this.queryList.class
                this.listQuery.courseId = this.queryList.course
                this.listQuery.state = this.queryList.status
                this.listQuery.createByName = this.queryList.submitted
                this.listQuery.observedStartTime = this.queryList.day[0]
                this.listQuery.observedEndTime = this.queryList.day[1]
                    this.getList(0)

            },
            /**
             * @Description: 重置
             * @DoWhat: 重置
             * @UseScenarios: 重置
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:24:14
             */
             reset () {
                this.listQuery.pageNum = 1
                this.listQuery.pageRow = 20
                this.listQuery.watchTitle =this.queryList.observeTitle = ''
                this.listQuery.deptId =this.queryList.departmentName= ''
                this.listQuery.supervisingTeacherId =this.queryList.supervisingTeacher= ''
                this.listQuery.gradeId =this.queryList.grade= ''
                this.listQuery.classId =this.queryList.class= ''
                this.listQuery.courseId =this.queryList.course= ''
                this.listQuery.state =this.queryList.status= ''
                this.listQuery.createByName =this.queryList.submitted= ''
                this.listQuery.observedStartTime = ''
                this.listQuery.observedEndTime = ''
                this.queryList.day = ''
                this.getList(0)
             },
            /**
             * @Description: 添加观察记录
             * @DoWhat: 添加观察记录
             * @UseScenarios: 添加观察记录
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:21:30
             */
            addObservationRecords () {
                console.log('添加观察记录')
                let obj = {
                    id: '',
                    type: 'add',
                    list: [],
                    selectT: []
                };

                this.$emit('handlerGoClassPatrolClick_record', obj)
            },
            /**
             * @Description: 导出
             * @DoWhat: 导出
             * @UseScenarios: 导出
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:21:34
             */
            exportTableInfo () {
                delete this.listQuery.pageNum
                delete this.listQuery.pageRow
                let form = this.listQuery
                console.log(form,'1111111');
                this.exportLoading = true;
                downloadFile({url: '/school/schoolClassRecord/export', form: form}, () => {
                this.$message.success('导出成功')
                this.exportLoading = false;
            }, () => {

            })
            },


            // table相关
            /**
             * @Description: 选中tableIdx
             * @DoWhat: 选中tableIdx
             * @UseScenarios: 选中tableIdx
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:29:15
             * @param {*} e
             */
            handleSelectionChange (e) {
                console.log(e,'2')
            },
            deduplication (arr) {
                let map = new Map();
                for (let item of arr) {
                    if (!map.has(item.id)) {
                        map.set(item.id, item);
                    }
                }
                return [...map.values()];
            },

            /**
             * @Description: 编辑巡课页面
             * @DoWhat: 编辑巡课页面
             * @UseScenarios: 编辑巡课页面
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 09:59:26
             */
            edit (data) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getSchoolClassRecordInfo({id: data.id, watchTitle: data.watchTitle}).then((res) => {
                    if (res.data.code == '200') {
                        let selectT = [];

                        this.getTeach(this.supervisingTeacherList, res.data.data.supervisingTeacherId ,selectT)
                        selectT = this.deduplication(selectT);

                        let obj = {
                            id: data.id,
                            type: 'edit',
                            list: [res.data.data],
                            selectT: selectT
                        };
                        this.savePageInfo();
                        this.$emit('handlerGoClassPatrolClick_record', obj)
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            /**
             * @Description: 继续巡课
             * @DoWhat: 继续巡课
             * @UseScenarios: 继续巡课
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-20 16:36:26
             */
             continueTour (data) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getContinueTourInfo({id: data.id, watchTitle: data.watchTitle}).then((res) => {
                    if (res.data.code == '200') {
                        let selectT = [];

                        this.getTeach1(this.supervisingTeacherList, res.data.data ,selectT)
                        selectT = this.deduplication(selectT);
                        let obj = {
                            id: data.id,
                            type: 'continueTour',
                            list: res.data.data,
                            selectT: selectT,
                            form: 'closeAdd'
                        };
                        this.savePageInfo();
                        this.$emit('handlerGoClassPatrolClick_record', obj)
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
             },
            /**
             * @Description: 巡课页面
             * @DoWhat: 巡课页面
             * @UseScenarios: 巡课页面
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:00:03
             */
            detail (data) {
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                teachingResearchCourseScheduleTempModel.getSchoolClassRecordInfo({id: data.id, watchTitle: data.watchTitle}).then((res) => {
                    if (res.data.code == '200') {
                        let selectT = [];
                        this.getTeach(this.supervisingTeacherList, res.data.data.supervisingTeacherId ,selectT)
                        selectT = this.deduplication(selectT);
                        let obj = {
                            id: data.id,
                            type: 'detail',
                            list: [res.data.data],
                            selectT: selectT
                        };
                        this.savePageInfo();
                        this.$emit('handlerGoClassPatrolClick_record', obj)
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            getTeach(supervisingTeacherList, supervisingTeacherId, selectT) {
                supervisingTeacherList.forEach((subItem) => {
                    if (supervisingTeacherId == subItem.id) {
                        let obj = {
                            id: subItem.id,
                            teacherName: subItem.name
                        };

                        selectT.push(obj)
                    }

                    if (subItem.children && subItem.children.length > 0) {
                        this.getTeach(subItem.children, supervisingTeacherId, selectT)
                    }
                })
            },
            getTeach1 (supervisingTeacherList, data, selectT) {
                supervisingTeacherList.forEach((subItem) => {
                    data.forEach((item) => {
                    if (item.supervisingTeacherId == subItem.id) {
                        let obj = {
                            id: subItem.id,
                            teacherName: subItem.name
                        };

                        selectT.push(obj)
                    }
                })

                if (subItem.children && subItem.children.length > 0) {
                    this.getTeach1(subItem.children, data, selectT)
                }
                })
            },
            /**
             * @Description: 删除列表记录
             * @DoWhat: 删除列表记录
             * @UseScenarios: 删除列表记录
             * @Attention: 无
             * @Author: 武东超
             * @Date: 2022-11-11 10:00:07
             */
            del (data) {
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const param = {
                    id: data.id,
                    watchTitle:data.watchTitle
                }
                this._fet('/school/schoolClassRecord/delete', param).then((res) => {
                    if (res.data.code == '200') {
                        this.$message.success('删除成功!')
                        this.getList()
                    } else if (res.data.msg){
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
            },
            // 修改年级查询项
            changeSel(data) {
                // 修改统计范围
                if (data.key === "grade") {
                    const classIndex = this.formData.data.findIndex(
                        (i) => i.key === "class",
                    );
                    this.formData.data[classIndex].value = "";
                    this.formData.data[classIndex].list = [];
                    if (data.value) {
                        this.getClassList(data.value);
                    }
                }
            },
            //根据年级获取班级下拉列表
            async getClassList(gradeId){
                const teachingResearchCourseScheduleTempModel = new TeachingResearchCourseScheduleTempModel();
                await teachingResearchCourseScheduleTempModel.getSchoolClassList({'schoolId': this.schoolId, 'organType': '4','parentList':gradeId }).then((res) => {
                if (res.data.code == '200') {
                    this.classList = res.data.data.list;
                    this.formData.data.find(e => e.key === 'class').list = res.data.data.list.map(item=>{
                        return Object.assign({}, item,{
                            label: item.name,
                            value: item.id
                        } )
                    })
                } else if (res.data.msg){
                    this.$message.error(res.data.msg)
                }
                })
            }
        },
        mounted(){
            setTimeout(() => {
                this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 132
            })
        }
    }
</script>
<style lang="scss" scoped>

.inline {
        margin: 4px 10px;
        width: 1px;
        height: 24px;
        background-color: #E6E6E6;
    }
    .teaching-research-classroom-observation-records-view {
        .content-wrapper {
            padding-right: 10px;
            .table-wrapper {
                margin-top: 10px;
                box-sizing: border-box;
            }
        }
    }
</style>
